<template>
  <v-card>
    <v-card-text>
      <v-expansion-panels multiple v-model="panelModel">
        <v-expansion-panel>
          <v-expansion-panel-header aut-panel-logo-left-logo
            >Left Logo</v-expansion-panel-header
          >
          <v-expansion-panel-content aut-panel-logo-left-logo-content>
            <v-row>
              <v-col cols="12" lg="4">
                <ImageField
                  aut-logo
                  v-model="imageObject"
                  :context="context"
                  :key="leftLogoKey"
                  @change="updateLeftLogoSrc"
                />
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  outlined
                  dense
                  aut-logo-width
                  v-model="leftLogoWidth"
                  label="Width"
                  type="number"
                  @keydown.esc.stop.prevent="leftLogoKey++"
                  @change="leftLogoKey++"
                />
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  outlined
                  dense
                  aut-logo-classes
                  v-model="leftLogoClasses"
                  label="Classes"
                  @keydown.esc.stop.prevent="imageKey++"
                  @change="imageKey++"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header aut-panel-logo-right-logo
            >Right Logo</v-expansion-panel-header
          >
          <v-expansion-panel-content aut-panel-logo-right-logo-content>
            <v-row>
              <v-col cols="12" lg="4">
                <ImageField
                  aut-logo-right
                  v-model="imageRightObject"
                  :context="context"
                  :key="rightLogoKey"
                  @change="updateRightLogoSrc"
                />
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  outlined
                  dense
                  aut-right-logo-width
                  v-model="rightLogoWidth"
                  label="Width"
                  type="number"
                  @keydown.esc.stop.prevent="rightLogoKey++"
                  @change="rightLogoKey++"
                />
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  outlined
                  dense
                  aut-logo-classes
                  v-model="rightLogoClasses"
                  label="Classes"
                  @keydown.esc.stop.prevent="imageKey++"
                  @change="imageKey++"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header aut-panel-logo-left-mobile
            >Left Mobile Logo</v-expansion-panel-header
          >
          <v-expansion-panel-content aut-panel-logo-left-mobile-content>
            <v-row>
              <v-col cols="12" lg="4">
                <ImageField
                  aut-logo
                  v-model="mobileImageObject"
                  :context="context"
                  :key="leftLogoMobileKey"
                  @change="updateLeftLogoMobileSrc"
                />
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  outlined
                  dense
                  aut-mobile-logo-width
                  v-model="leftLogoMobileWidth"
                  label="Width"
                  type="number"
                  @keydown.esc.stop.prevent="leftLogoMobileKey++"
                  @change="leftLogoMobileKey++"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header aut-panel-logo-right-mobile
            >Right Mobile Logo</v-expansion-panel-header
          >
          <v-expansion-panel-content aut-panel-logo-right-mobile-content>
            <v-row>
              <v-col cols="12" lg="4">
                <ImageField
                  aut-mobile-logo-right
                  v-model="mobileimageRightObject"
                  :context="context"
                  :key="mobilerightLogoKey"
                  @change="updateRightLogoMobileSrc"
                />
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  outlined
                  dense
                  aut-mobile-right-logo-width
                  v-model="rightLogoMobileWidth"
                  label="Width"
                  type="number"
                  @keydown.esc.stop.prevent="mobilerightLogoKey++"
                  @change="mobilerightLogoKey++"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header aut-panel-logo-fav-icon
            >Fav Icon</v-expansion-panel-header
          >
          <v-expansion-panel-content aut-panel-logo-fav-icon-content>
            <v-row>
              <v-col cols="12">
                <ImageField
                  aut-favicon
                  v-model="faviconImageObject"
                  :context="context"
                  :key="faviconKey"
                  @change="updateFaviconImageSrc"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header aut-panel-logo-others
            >Others</v-expansion-panel-header
          >
          <v-expansion-panel-content aut-panel-logo-others>
            <FeatureSwitches :features="features" @update="updateSettings" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" aut-update-logo @click="updateSkin" class="ml-2"
        >Update</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { set } from "lodash";
import FeatureSwitches from "@/views/administration/FeatureFlags/FeatureSwitches/FeatureSwitches.vue";
import { logoFeatures } from "@/settings";
import { mapActions } from "vuex";
export default {
  name: "SettingsLogo",
  props: {
    definition: {
      type: Object,
    },
  },
  data() {
    return {
      panelModel: 0,
      features: logoFeatures,
      leftLogoSrc: this.definition?.logo?.image || "",
      leftLogoWidth: this.definition?.logo?.width,
      leftLogoClasses: this.definition?.logo?.classes || "",
      rightLogoClasses: this.definition?.logoRight?.classes || "",
      rightLogoSrc:
        this.definition?.logoRight?.image ||
        this.definition?.logo2?.image ||
        "",
      rightLogoWidth:
        this.definition?.logoRight?.width || this.definition?.logo2?.width,
      leftMobileSrc: this.definition?.mobile?.logo?.image || "",
      rightMobileLink: this.definition?.mobile?.logoRight?.image || "",
      leftLogoMobileWidth: this.definition?.mobile?.logo?.width,
      rightLogoMobileWidth: this.definition?.mobile?.logoRight?.width,
      widthMobileRight: this.definition?.mobile?.logoRight?.width,
      faviconLink: this.definition?.favicon?.image || "",
      leftLogoKey: 1,
      rightLogoKey: 1,
      leftLogoMobileKey: 1,
      mobilerightLogoKey: 1,
      faviconKey: 1,
      context: "dummy",
    };
  },
  computed: {
    imageObject() {
      return {
        name: "skin_logo",
        label: "",
        type: "image",
        mode: "input",
        display: {
          embed: {
            width: this.leftLogoWidth,
          },
        },
        value: this.leftLogoSrc,
      };
    },
    imageRightObject() {
      return {
        name: "skin_logo2",
        type: "image",
        mode: "input",
        display: {
          embed: {
            width: this.rightLogoWidth,
          },
        },
        value: this.rightLogoSrc,
      };
    },
    mobileImageObject() {
      return {
        name: "skin_mobileLogo",
        type: "image",
        mode: "input",
        display: {
          embed: {
            width: this.leftLogoMobileWidth,
          },
        },
        value: this.leftMobileSrc,
      };
    },
    mobileimageRightObject() {
      return {
        name: "skin_mobileRightLogo",
        type: "image",
        mode: "input",
        display: {
          embed: {
            width: this.rightLogoMobileWidth,
          },
        },
        value: this.rightMobileLink,
      };
    },
    faviconImageObject() {
      return {
        name: "skin_favicon_logo",
        label: "Favicon Logo",
        hint: "Logo to display as Favicon (on tabs in the browser view)",
        type: "image",
        mode: "input",
        value: this.faviconLink,
      };
    },
  },
  components: {
    FeatureSwitches,
    ImageField: () => import("@/components/fields/ImageField/ImageField.vue"),
  },
  methods: {
    ...mapActions(["saveSettingsToDomain"]),
    updateSettings({ name, value }) {
      const settings = {};
      set(settings, name, value);
      this.saveSettingsToDomain(settings);
    },
    updateLeftLogoSrc(newSrc) {
      this.leftLogoSrc = newSrc;
    },
    updateRightLogoSrc(newSrc) {
      this.rightLogoSrc = newSrc;
    },
    updateLeftLogoMobileSrc(newSrc) {
      this.leftMobileSrc = newSrc;
    },
    updateRightLogoMobileSrc(newSrc) {
      this.rightMobileLink = newSrc;
    },
    updateFaviconImageSrc(newSrc) {
      this.faviconLink = newSrc;
    },
    updateSkin() {
      const payload = {
        logo: {
          image: this.leftLogoSrc,
          width: this.leftLogoWidth,
          classes: this.leftLogoClasses,
        },
        logoRight: {
          image: this.rightLogoSrc,
          width: this.rightLogoWidth,
          classes: this.rightLogoClasses,
        },
        mobile: {
          logo: {
            image: this.leftMobileSrc,
            width: this.leftLogoMobileWidth,
          },
          logoRight: {
            image: this.rightMobileLink,
            width: this.rightLogoMobileWidth,
          },
        },
        favicon: {
          image: this.faviconLink,
        },
      };
      this.saveSettingsToDomain(payload);
      this.displaySuccessMessage("Logos have been updated!");
    },
  },
};
</script>
